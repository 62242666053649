@import '@honeycomb-npm/honeycomb/src/foundations/colors/_aliases.scss';

.signed-out {
    .log-off-user {
        .thanks-text {
            font-size: 20px;
        }
    }

    .commbiz-mobile-section-img,
    .commbiz-mobile-section-img-top {
        img {
            width: 100%;
            height: 140px;
        }
    }
}