@import "@honeycomb-npm/honeycomb/src/foundations/colors/_aliases.scss";

.browser-support{
  background-color: $hyperlinkDark;
  span {
    color: $backgroundLight;
    text-align: center;
    display: block;
    padding: 24px 0;
    a {
      color: $backgroundLight !important;
      border-bottom: 1px solid $backgroundLight !important;
      margin-left: 5px;
      &:hover {
        border-bottom: 1px solid none !important;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
.browser-support {
  margin: -8px;
  margin-bottom: 8px;
  }
}