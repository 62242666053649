@import "@honeycomb-npm/honeycomb/src/foundations/colors/_aliases.scss";

html {
  height: 100%;
}

body {
  background: $backgroundLight;
}

@media only screen and (max-width: 767px) {
  .honeycomb .container {
    min-width: 100% !important;
  }
}
