@import '@honeycomb-npm/honeycomb/src/foundations/colors/_aliases.scss';

.modal-dialog {
  .modal-spinner-overlay {
    position: absolute;
    background-color: $backgroundLight;
    width: 100%;
    height: 100%;
    z-index: 2000;
    left: 0;

    .spinner__indicator {
      top: 40%;
    }
  }

  .modal-content {
    padding-bottom: 48px;

    .modal-header {
      padding-bottom: 0 !important;
    }

    .modal-body {
      padding-bottom: 0 !important;
    }
  }

  .hidden-element {
    opacity: 0;
  }

  .token-modal-header {
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    font-family: CBASans;
  }

  .token-modal-logon-id-layout {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    margin-bottom: 28px;

    .token-modal-logon-id-label {
      font-weight: bold;
      line-height: 1.5 !important;
    }

    .token-modal-logon-id-text {
      font-weight: normal;
      line-height: 1.5 !important;
    }
  }

  .token-modal-input-layout {
    display: flex;

    .token-modal-input-password {
      width: 238px;
      margin-right: 20px;
    }

    .token-modal-input-token {
      width: 238px;
    }

    label {
      display: block !important;
      font-weight: bold !important;
    }
  }

  .token-modal-error-msg-layout {
    display: flex;
    align-items: baseline;
    margin-top: 4px;

    .icon_content {
        display: flex;
        align-items: baseline;
        margin-right: 4px;
    }

    .token-modal-error-msg-text {
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: #e1001a;
    }
  }

  .token-modal-btn-layout {
    display: flex;

    .token-modal-btn-done {
      width: 238px;
      margin-right: 20px;
      margin-bottom: 0;
    }

    .token-modal-btn-cancel {
      width: 238px;
      margin-right: 0;
      margin-bottom: 0;
    }
  }
}

@media only screen and (max-width: 599px) {
  .modal-content {
    padding-bottom: 8px !important;

    .modal-header {
      padding-bottom: 0 !important;
      padding-left: 1.5em !important;
      padding-bottom: 1em !important;
    }

    .modal-body {
      padding: 0 24px !important;
    }

    .modal-footer {
      padding-left: 1em !important;
      display: inline-flex;
    }
  }

  .token-modal-input-layout {
    flex-direction: column;

    .token-modal-input-password {
      width: 100% !important;
      margin-right: 0 !important;
      margin-bottom: 36px;
    }

    .token-modal-input-token {
      width: 100% !important;
      margin-right: 0 !important;
    }
  }

  .token-modal-btn-layout {
    flex-direction: column;

    .token-modal-btn-done {
      width: 100% !important;
      margin-right: 0 !important;
      margin-bottom: 16px !important;
    }

    .token-modal-btn-cancel {
      width: 100% !important;
      margin-right: 0 !important;
    }
  }
}

input#dialoguePassword,
input#dialogueToken {
  height: 48px;
}
