@import "@honeycomb-npm/honeycomb/src/foundations/colors/_aliases.scss";

footer {
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.71;
    letter-spacing: normal;
    color: $textDarkSecondary;
  }