@import "@honeycomb-npm/honeycomb/src/foundations/colors/_aliases.scss";

.background-image {
    position: absolute;
    min-height: 100%;
    height: 1280px;
    top: 0;
    left: 59vw;
    right: 0;
    z-index: -1;
    background: url("../../images/img_xl_city.jpg") no-repeat;
    background-size: cover;
}

.login-wrapper {
    margin-top: 50px;

    .spinner-overlay {
        position: absolute;
        background-color: rgba(255, 255, 255, 0.7);
        width: 100%;
        height: 100%;
        z-index: 2000;
    }
    .hidden-element {
        opacity: 0;
    } 
}

.login-card {
    background: $backgroundLight;
    box-shadow: 0 4px 5px 0 rgba(35, 31, 32, 0.08), 0 8px 10px 1px rgba(35, 31, 32, 0.16);
    padding-left: 0 !important;
    padding-right: 0 !important;
    z-index: 0;
}

input:-internal-autofill-selected {
    background-color: $backgroundLight !important;
    background-image: none !important;
    color: rgb(0, 0, 0) !important;
}

.float-panel {
    background: $backgroundLight;
    border-bottom: 1px solid $divider;
}

.login-box {
    background: $backgroundLight;
    box-shadow: 0 4px 5px 0 rgba(35, 31, 32, 0.08), 0 8px 10px 1px rgba(35, 31, 32, 0.16);
    padding: 25px 0;
    line-height: 3.2;
    width: 100%;

    a {
        margin-left: 5px;
        border-bottom: none !important;
    }

    .btn-action--emphasis,
    .btn-action--emphasis:hover {
        width: 95%;
        margin: 0 !important;
    }

    a {
        margin-left: 5px;
    }
}

.login-card span {
    font-size: 20px;   
    line-height: 1.4;
    color: $textDark;
    font-family: CBASans, sans-serif !important;
}

.login-card label {
    margin-bottom: 0 !important;
    margin-left: 32px;
    font-weight: bold !important;
    width: auto;
}

input#password,
input#loginId,
input#token {
    border: none !important;
    font-size: 20px !important;
    font-weight: normal;
    width: 100%;
    z-index: 1500;
    padding-left: 1.6em;
    padding-right: 1.7em;
    padding-top: 1.5em;
}

.login-headline {
    h1.Logon_text {
        width: 22rem;
        font-size: 3.5rem;
        line-height: 1 !important;
    }

    p {
        max-width: 30vw;
        font-size: 20px !important;
    }
}

.Slab48Left {
    font-size: 3.5em !important;
}

.validationFocus {
    border: 2px solid $critical;
}

.inputFocus {
    border: solid 2px $hyperlinkDark;
}

.err-msg {
    line-height: 3;
    border-bottom: 1px solid $divider;

    .err-ico {
        display: flex;
        align-items: flex-start;

        #ErrorMessage {
            display: flex;
            img {
                width: 24px;
                height: 24px;
            }
        }

        span {
            color: $critical;
            font-size: 16px;
            font-style: normal;
            font-weight: normal;
            font-stretch: normal;
            line-height: 1.5;
            letter-spacing: normal
        }
    }
}

@media only screen and (min-width: 768px) {
    .register-link-mobile {
        display: none;
    }
}

.floating-label {
    position: absolute;
    top: 0;
    left: 0;
    user-select: none;
    z-index: 1500;
    padding-top: 2em;
}

.floating-label-field {
    height: 104px;
    border-width: 0;
    z-index: 2000;
    padding-top: 1.5em;
}

.floating-label-field:focus {
    outline: 0;
    box-shadow: none;
}

.floating-label,
.floating-label-field {
    line-height: 1;
    display: block;
    width: 100%;
}

.floating-label-field,
.floating-label-wrap {
    position: relative;
}

.floating-label-field {
    background-color: transparent;
}

.floating-label-field +.floating-label {
    z-index: 1500;
    font-size: 20px !important;
}

.floating-label-field:focus +.floating-label {
    transform: translate(0.1em, -10%) scale(1);
    padding-top: 1.5em !important;
    font-size: 16px !important;
}

 .floating-label.focus {
    padding-top: 1.5em !important;
    font-size: 16px !important;
}

.floating-label-field::placeholder {
    color: transparent;
}

.login-wrapper .modal-content .modal-body{
    p {
        word-break: break-word;
        width: 95%;
        line-height: 2.5;
    }
}

.honeycomb .spinner .spinner__indicator::after {
    top: 0.3rem !important;
    bottom: .3rem !important;
    left: .3rem !important;
    right: .3rem !important;
}

.honeycomb .spinner .spinner__indicator {
    height: 40px !important;
    width: 40px !important;
    margin: 0 auto !important;
    top: 10em;
    z-index: 1;
    position: absolute !important;
    bottom: 0;
    left: 0;
    right: 0;
}

.consent-container {
    display: flex;
    gap: 12px;
    line-height: 1.5;
    background-color: #f1f7fb;
    border-left: 6px $textAction solid;
    padding: 15px 15px 15px 20px;
    margin-bottom: 18px;

    .info-icon {
        height: 24px;
    }
}

@media only screen and (max-width: 599px) {
    .floating-label-field {
        height: 88px;
        border-width: 0;
        z-index: 2000;
    }

    input#password,
    input#loginId,
    input#token {
        border: none !important;
        font-size: 16px !important;
    }

    .floating-label-field +.floating-label {
        z-index: 1500;
        font-size: 16px !important;
    }
    
    .floating-label-field:focus +.floating-label {
        transform: translate(0.1em, -10%) scale(1);
        padding-top: 1em !important;
        font-size: 14px !important;
    }
    
     .floating-label.focus {
        font-size: 14px !important;
    }
}
