@import "@honeycomb-npm/honeycomb/src/foundations/colors/_aliases.scss";

.black-content-wrapper {
    background: $textDark;
    position: relative;
    min-height: 400px;
    overflow: hidden;

    .black-content {
        border-left: 1px solid $iconDark;
        padding-left: 30px !important;
        padding-right: 43px !important;
        display: flex;
        flex-direction: column;

        &:nth-child(2) {
            border-left: none;
        }

        &:last-child {
            #comm-mobile {
                order: 1;
            }

            .commbiz-mobile-section-img {
                order: 2;
            }
        }

        .contentHeading {
            color: $textLight !important;
            margin-top: 0;
            font-size: 20px;
            font-weight: bold;
            line-height: 1.4;
        }

        .contentParagraph {
            font-size: 16px;
            line-height: 1.5;
            color: $textLight !important;
            padding: 0;
            padding-right: 10px;
        }

        ul {
            padding: 0px;
        }

        a {
            font-size: 16px;
            line-height: 1.5;
            color: $textLight;
            border: none;
            border-bottom: 1px solid $hyperlinkLight;

            &:hover {
                color: $hyperlinkLight;
                border-bottom: 1px solid $hyperlinkLight;
            }
        }
    }

    .spinner-overlay {
        position: absolute;
        background-color: rgba(255, 255, 255, 0);
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 2000;
    }

    .hidden-element {
        opacity: 0;
    }
}

#helpful-links li {
    display: block;   
    list-style: none;
    padding-bottom: 24px;
}

#user-guides li {
    display: block;    
    list-style: none;
    line-height: 1;
    padding-bottom: 24px;
}

#helpful-links li a,
#user-guides li a {
    text-decoration: none;
    border: none;
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: normal;
    color: $textLight;
}

#helpful-links li a:hover,
#user-guides li a:hover {
    border-bottom: 1px solid $hyperlinkLight;
    color: $hyperlinkLight;
}

.commbiz-mobile-section{
    span{
        display: block;
        padding-top: 10px;
    }
}

.commbiz-mobile-section-img,
.commbiz-mobile-section-img-top {
    img {
        width: 100%;
        height: 140px;
    }
}

.commbiz-mobile-section-img-top{
    display:none;
}

@media only screen and (max-width: 599px) {
    header {
        height: 64px;
    }

    .logo img {
        width: 32px;
        height: 32px;
        margin-left: 24px;
        margin-top: 16px;
    }

    .commbiz-mobile-section-img-top {
        display: block;
    }

    .commbiz-mobile-section-img{
        display:none;
    }

    .commbiz-mobile-section-img {
        display: none;
    }
    
    .login-card label {
        margin-left: 16px;
    }
    
    input#password,
    input#loginId,
    input#token,
    .black-content-wrapper .black-content,
    .col-sm-12.login-box.pl-4 {
        padding-left: 16px !important;
    }
    
    .login-headline.pl-4, .login-headline h1.Logon_text {
        margin-left: -9px !important;
    }

    .footer-align {
        margin-left: -18px !important;
    }

    .black-content-wrapper {
        .black-content {
            &:last-child {
                #comm-mobile {
                    order: 2;
                }
                .commbiz-mobile-section-img {
                    order: 1;
                    flex: 1;
                    margin-bottom: 1.5rem;
                }
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    #user-guides li {
        display: block;
    }

    .logo span {
        display: none;
    }

    .login-headline {
        h1 {
          width: auto!important;
          font-size: 24px !important;
          padding-top: 31px;
          margin: 0 !important;
          padding-bottom: 27px;
          padding-left: 0 !important;
        } 
    
        p {
          display: none;
      }
    }

    .black-content-wrapper{
        .black-content {
           border-bottom: 1px solid $iconDark !important;
           border-left: none !important;
           margin-bottom: 15px;
   
               &:last-child{
                   border-bottom: none !important;
               }
           }
       }
}

@media only screen and (min-width: 768px) and (max-width: 977px) {
    #helpful-links {
        li a {
            line-height: 0;
        }
    }

    .black-content-wrapper{
     .black-content {
        border-bottom: 1px solid $iconDark !important;
        border-left: none !important;
        margin-bottom: 15px;

            &:last-child{
                border-bottom: none !important;
            }
        }
    }
}