@import "@honeycomb-npm/honeycomb/src/foundations/colors/_aliases.scss";

header{
  background: $backgroundLight;
  -webkit-box-shadow: 0 2px 2px 0 rgba(35, 31, 32, 0.2);
  -moz-box-shadow: 0 2px 2px 0 rgba(35, 31, 32, 0.2);
  box-shadow: 0 2px 2px 0 rgba(35, 31, 32, 0.2);
  height: 80px;

  display: flex !important;
  align-items: center;
  justify-content: space-between;

  .logo {
    display: flex;
    align-items: center;

    img {
      width: 40px;
      height: 40px;
      margin: 1rem 0;
    }

    span {
      font-weight: bold !important;
      line-height: 1.5;
      letter-spacing: normal;
      color: $textDark !important;
      vertical-align: middle !important;
    }
  }

  .register-link {
    border: none !important;

    &:hover {
      text-decoration: underline !important;
    }
  }
}

@media only screen and (max-width: 767px) {
  .background-image {
    display: none;
  }

  .login-wrapper {
    margin-top: 0 !important;
  }

  .login-card {
    background: none;
    padding-left: inherit;
    padding-right: inherit;
  }

  .mobile-container{
    padding:0.5rem;
  }
}

@media only screen and (max-width: 600px) {
  .register-link{
    display: none;
  }

  .register-link-mobile {
    display: block;
  }
}
